<template>
  <div class="home">
    <HelloWorld msg="But what is it" />
    <div class="container">
      <div class="btn btn-primary">Hello</div>
      <p class="text-one">0 123456789 . , - + * ^ % ! / \ ERR</p>
    </div>
    <div class="container">
      <digit var1="8" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import Digit from '../components/Digit.vue';

export default {
  name: 'Home',
  components: {
    HelloWorld,
    Digit,
  },
};
</script>

<style scoped>
.text-one {
  font-family: digitalFont;
  font-size: 4rem;
  color: greenyellow;
  background-color: green;
  padding: 12px;
}
</style>
