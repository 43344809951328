<template>
  <div>Digit {{ var1 }}</div>
</template>

<script>
import { toRefs } from 'vue';

export default {
  setup(props) {
    const { var1 } = toRefs(props);
    return {
      var1,
    };
  },
};
</script>

<style scoped>
</style>
